import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "52",
  height: "52",
  viewBox: "0 0 52 52",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "download" }, [
      _createElementVNode("path", {
        id: "Vector",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M31.9062 19.1839L28.0222 23.0679V3L23.9224 3V23.3177L19.6629 19.0582L16.764 21.9572L25.8474 31.0406L34.8052 22.0828L31.9062 19.1839ZM46.7125 40.558L6 40.558V34.2249L46.7125 34.2249V40.558ZM46.7125 49.7728L6 49.7728V43.4398L46.7125 43.4398V49.7728Z",
        fill: "#212529"
      })
    ], -1)
  ])))
}
export default { render: render }